import SinglePostContent from '../../components/single-post-content/SinglePostContent';
import './SinglePost.css';

export default function SinglePost() {

  return (
    <div className='single-post'>
        <SinglePostContent />
    </div>
  )
}
